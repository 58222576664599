import React from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@vc-components/ui/popover";
import { Button } from "@vc-components/ui/button";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "@vc-components/ui/calendar";
import { ControllerRenderProps } from "react-hook-form";

const DateTimepicker = React.forwardRef<
  HTMLInputElement,
  ControllerRenderProps
>(({ onChange, value: date, ...rest }, ref) => {
  return (
    <Popover>
      <div ref={ref}>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "w-[250px] justify-start text-left f-Montserrat pl-1",
              !date && "text-muted-foreground",
              "rounded-full bg-white"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "PPP") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 bg-white">
          <Calendar
            {...rest}
            mode="single"
            selected={date}
            fromDate={new Date()}
            onSelect={(va) => {
              onChange(va);
            }}
            initialFocus
          />
        </PopoverContent>
      </div>
    </Popover>
  );
});

export default DateTimepicker;
