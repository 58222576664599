import { useAuth } from "@/context/auth";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getUserStripe } from "@vc-services/stripeServices";

export const usePaginatedRides = () => {
  const { userData } = useAuth();
  const { stripeCustomer } = userData || {};
  return useInfiniteQuery({
    queryKey: ["Rides"],
    queryFn: async ({ pageParam = 0 }) => {
      if (stripeCustomer) {
        const { data: customer } = await getUserStripe({
          customerId: stripeCustomer,
          limit: 5,
          cursor: pageParam,
        });
        return {
          paymentIntentsList: customer.paymentIntentsList,
          nextCursor: customer.nextCursor,
        };
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.nextCursor;
    },
  });
};
