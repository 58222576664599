// import { MAPBOX_KEY } from '../constants/secret';
import { formSchema } from "@vc-components/BookForm/BookFormcomponent";
import Mapbox from "../components/Mapbox";
import mapboxgl from "mapbox-gl";
import { CourseModel } from "src/constants/types/Courses";

import { z } from "zod";
import { api, apiAuth } from "@/utils/api";
// const mapboxAPI = {
//     api: 'https://api.mapbox.com/',
//     version: 'v5/',
//     profile: 'mapbox/driving-traffic/',
//     service: 'directions/'
// }

// interface trajetModel {
//   [x: string]: { place: string; coordinates: string[]; address: string };
//   // [x: string]: any;
// }

export const getRoutes = async (trajet: CourseModel) => {
  const coordinates = `${trajet.depart.coordinates.join()};${trajet.arrive.coordinates.join()}`;
  const {
    data: { route },
  } = await api.post("/Mapbox/getRouteInfo", {
    coordinates,
    trajet,
  });

  if (route.code === "NoRoute") {
    return route;
  }

  // traceWay(res.route.source);
  return route;
};

export const orlylink = async (payload: z.infer<typeof formSchema>) => {
  const { data: result } = await apiAuth.post(
    `/Mapbox/formula/orlylink`,
    payload
  );
  return result;
};

const arrMarker: mapboxgl.Marker[] = [];
export const traceWay = (source: any, map?: any) => {
  map = map ? map : Mapbox.map;
  // console.log('trace a route');
  arrMarker.map((e) => e.remove()); // efface les markers
  arrMarker.length = 0; // clear la variable
  map.getLayer("route") && map.removeLayer("route");
  map.getSource("route") && map.removeSource("route");
  const depart = new mapboxgl.Marker()
    .setLngLat(source.coordinates[0])
    .addTo(map);
  const arrive = new mapboxgl.Marker()
    .setLngLat(source.coordinates[source.coordinates.length - 1])
    .addTo(map);

  arrMarker.push(depart);
  arrMarker.push(arrive);

  if (map.getSource("route") === undefined) {
    // console.log(source)
    map.fitBounds(
      [
        source.coordinates[0],
        source.coordinates[source.coordinates.length - 1],
      ],
      { padding: 30 }
    );
    map.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: source,
      },
    });
    map.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "miter",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#29E",
        "line-width": 7,
      },
    });
  }
};
