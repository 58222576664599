import OrlyTitle from "@vc-components/Home/HomeCards/OrlyTitleComponent";

import * as ROUTES from "@/constants/routes";

import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";

import orly from "@vc-assets/images/plane.svg";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { z } from "zod";

import DateTimepicker from "./Datepicker";
import { TimePicker } from "./Timepicker";
import { UserRound } from "lucide-react";
import moment, { Moment } from "moment";
import { Input } from "@vc-components/ui/input";
import SelectInput from "@vc-components/Home/SelectInput";
import SwipeFieldsComponent from "./inputSwitchComponents/SwipeFieldsComponent";
import { useAuth } from "@/context/auth";
import { useNavigate } from "react-router";
import { orlylink } from "@vc-services/mapboxServices";

export const formSchema = z.object({
  startDate: z.union([z.date(), z.string()]),
  nbPlace: z.union([z.string(), z.undefined()]),
  course: z.object({
    type: z.string(),
    depart: z.string(),
    target: z.string(),
    input: z.string().optional(),
  }),
  server: z.string().optional(),
});


function roundUpToNext15Minutes(date: Moment) : Moment {
  const remainder = 15 - (date.minute() % 15); // Calcule le reste pour atteindre la prochaine tranche de 15 min
  return date.add(remainder, "minutes").startOf("minute"); // Ajoute les minutes restantes et arrondit
}

const BookFormComponent: React.FC = () => {
  const defaultDate = roundUpToNext15Minutes(
    moment().add(30, "minutes")
  ).toDate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      startDate: defaultDate,
      nbPlace: "1",
      course: {
        type: "orlyLink",
        depart: "paris",
        target: "orly",
      },
    },
  });
  // const { target, depart } = form.getValues("course") || {};
  const {
    setValue,
    formState: { errors },
    setError,
  } = form;

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    //  await (await orlylink(values))?.json();
    // faire en sorte que si un driver est déjà présent sur place on peut quand même réserver 5min avant
    try {
      const resp = !user ? navigate(ROUTES.SIGN_UP) : await orlylink(values);
      if (resp?.success) {
        navigate(ROUTES.PAYMENTS, {
          state: {
            ...values,
            modalOpen: true,
            courseId: resp.success.course_id,
            slotId: resp.success.slot_id,
          },
        });
      }
    } catch (error: Error | any) {
      if (error?.response) {
        const { response } = error || {};
        const {
          data: {
            error: { message, details },
          },
        } = response || {};
        setError("server", { message: details?.type || message });
      } else {
        setError("server", { message: error.message });
      }
    }
    // if (resp?.error) {
    // }

    // console.log("response submit", resp);
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
  }

  const handleChange = (dateChange: Date) => {
    setValue("startDate", dateChange);
  };

  const switchAction = () => {
    const newCourse = { type: "orlyLink", target: depart, depart: target };
    setValue("course", newCourse);
  };
  const { target, depart } = form.watch("course") || {};
  return (
    <div className="inline-center">
      <OrlyTitle title={t("orlylink.title")} />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-8 items-center flex-col flex mt-9"
        >
          <div className="flex-row flex items-center">
            <SwipeFieldsComponent
              clickAction={switchAction}
              firstComponent={
                <FormField
                  control={form.control}
                  name={"course.depart"}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center">
                          <Input
                            className="hidden text-white text-center text-xl border-0 w-[168px] pr-0 px-1 capitalize cursor-default"
                            readOnly={true}
                            placeholder="Paris"
                            {...field}
                          />
                          <span className="text-white text-center text-xl border-0 w-[168px] pr-0 px-1 capitalize cursor-default">
                            {"Paris"}
                          </span>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              }
              secondComponent={
                <FormField
                  control={form.control}
                  name="course.target"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-center lg:w-[168px] cursor-default">
                      <FormControl>
                        <SelectInput icon={orly} sens={field.name} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              }
            />
          </div>
          <p className="f-color-white text-xl mt-1 mb-0">
            {t("formule.instruction")}
          </p>
          <div className="flex flex-row justify-center items-center mt-1">
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <DateTimepicker {...field} onChange={handleChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="timepicker max-w-28 ms-5">
              <Controller
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <TimePicker
                    setDate={(_date: Date) => {
                      handleChange(_date);
                    }}
                    date={field.value as Date}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="placeNumber mt-2">
            <FormField
              control={form.control}
              name="nbPlace"
              render={({ field }) => (
                <FormItem className="flex flex-row-reverse items-center">
                  <FormLabel className="p-0 m-0 text-white">
                    12.5€/pers.
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[80px] bg-slate-50 px-[5px] rounded-full f-Montserrat">
                        <div className="flex h-10 items-center rounded-s-full">
                          <UserRound className="mx-2 h-4 w-4" />
                        </div>
                        <SelectValue placeholder={t("form.placeNumber")} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="bg-slate-50">
                      <SelectItem className="cursor-pointer" value="1">
                        1
                      </SelectItem>
                      <SelectItem className="cursor-pointer" value="2">
                        2
                      </SelectItem>
                      <SelectItem className="cursor-pointer" value="3">
                        3
                      </SelectItem>
                      <SelectItem className="cursor-pointer" value="4">
                        4
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {errors.server && (
            <FormMessage className="text-red-400">
              {errors.server.message}
            </FormMessage>
          )}
          <Button
            className="btn btn-primary rounded-full text-slate-50"
            type="submit"
          >
            {t("button.booking-sht")}
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default BookFormComponent;
