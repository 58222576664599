import SwipeButton from "@vc-components/commons/Button/SwipeButton";
import { useRef, useState } from "react";

const SwipeFieldsComponent = ({
  firstComponent,
  secondComponent,
  clickAction,
}: {
  firstComponent: React.ReactElement;
  secondComponent: React.ReactElement;
  clickAction?: () => void;
}) => {
  const [, setRotateComponent] = useState(true);
  const leftComponent = useRef(firstComponent);
  const rightComponent = useRef(secondComponent);
  const tempComponent = useRef(secondComponent);
  const switchAction = () => {
    tempComponent.current = rightComponent.current;
    rightComponent.current = leftComponent.current;
    leftComponent.current = tempComponent.current;
    clickAction?.();
    setRotateComponent((e) => {
      return !e;
    });
  };
  return (
    <>
      {leftComponent.current}
      <SwipeButton onClick={switchAction} />
      {rightComponent.current}
    </>
  );
};

export default SwipeFieldsComponent;
