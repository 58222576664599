import { ResponsePayment } from "./payment";
import * as ROUTES from "src/constants/routes";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useEffect, useState } from "react";
import { getSession, cancelSession } from "src/services/stripeServices";
import Stripe from "stripe";
import { useNavigate } from "react-router";
import { dateFormat } from "@/utils/date_utils";

const STATUS = {
  success: "success",
  cancel: "cancel",
  error: "error",
};
const PaymentStatus = ({ status, sessionId }: ResponsePayment) => {
  const { t } = useTranslation();
  const [session, setSession] = useState<Stripe.Checkout.Session | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleSession = async () => {
      switch (status) {
        case STATUS.error: {
          try {
            const { data: cancelResp } = await getSession(sessionId);
            setSession(cancelResp);
          } catch (error) {
            console.error("error while cancelling session", error);
          }
          break;
        }
        case STATUS.cancel: {
          try {
            const { data: cancelResp } = await cancelSession(sessionId);
            setSession(cancelResp);
          } catch (error) {
            console.error("error while cancelling session", error);
          }
          break;
        }
        default: {
          try {
            const { data: sessionJson } = await getSession(sessionId);
            setSession(sessionJson);
            // Stripe.generateInvoice({ sessionData });
          } catch (error) {
            console.error(`Error while fetching Session`, error);
          }
        }
      }
    };
    handleSession();
  }, [status, sessionId]);
  const handlePaiement = () => {
    if (session?.status === "open") {
      navigate(`${ROUTES.BOOKORLYLINK}/${ROUTES.PAYMENTS}`, {
        state: { sessionId },
      });
    }
  };

  const { invoice_creation } = session || {};
  const { invoice_data } = invoice_creation || {};
  const { metadata } = invoice_data || {};
  const { datetime } = metadata || {};
  const formatdate = dateFormat(datetime, t("dateformat.short"));
  return (
    <div className="cadre_payment_status cadre_vc info f-Montserrat">
      <span className="flex text-6xl capitalize flex-shrink-0 items-center flex-1 f-Montserrat font-extrabold text-darkGrey m-5">
        {t("orlylink.title")}
      </span>
      <div className="flex flex-1 flex-col items-center">
        <span className="status-content">{t(`payment.${status}`)}</span>
        {status === STATUS.success && (
          <div className="flex flex-col items-center mb-2">
            <span>{t("payment.info")}</span>
            <span className="mt-2 text-xl first-letter:uppercase ">
              {t("course.takein")}
            </span>
            <span className="first-letter:uppercase text-xl">{formatdate}</span>
          </div>
        )}
        <div className="payment_footer">
          {session?.status === "open" && (
            <span
              className="cursor-pointer block text-center first-letter:uppercase"
              onClick={handlePaiement}
            >
              {t("payment.backToPayment")}
            </span>
          )}
          <span className="thanks-content flex">
            {session?.status === "complete" && t(`payment.thanks`)}
            
          </span>
          <span>{t(`payment.seeyou`)}</span>
          <br />
          <span
            className="backBtn cursor-pointer"
            onClick={() => navigate(ROUTES.HOME)}
          >
            {t("payment.backToHome")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
