import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { convertData as convert } from "./utils";

import { MAPBOX_KEY } from "../../constants/secret";
import { useTranslation } from "react-i18next";

export default function SelectInput(props: any) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [optionValue, setOptions] = useState("Aéroport de Paris-Orly");
  useEffect(() => {
   (async () => {
      const request = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          optionValue
        )}.json?access_token=${MAPBOX_KEY}`
      );
      const response = await request.json();
      const courseResponse = convert(response.features[0]);

      dispatch({
        type: "UPDATE_DATA_ROUTE",
        data: { [props.sens]: { ...courseResponse, completed: true } },
      });
    })();
  }, [dispatch, optionValue, props.sens]);

  return (
    <div className="text-white flex-row flex items-center">
      <select
        name={props.sens}
        value={optionValue}
        onChange={(e) => setOptions(e.target.value)}
        style={{ display: "none" }}
      >
        <option value="Aéroport de Paris-Orly"></option>
      </select>
      <img className="mr-1 orly" src={props.icon} alt="plane-icon" />
      <span>
        <span className="uppercase"> {t('orlylink.airport')} </span>
        <span className="f-Circular-Black">ORLY</span>
      </span>
    </div>
  );
}
