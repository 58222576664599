import { ReactElement } from "react";
import { Outlet } from "react-router";

export default function LayoutBooking(): ReactElement {
  return (
    <>
      <Outlet />
    </>
  );
}
