import { useLocation, Navigate } from "react-router-dom";
import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  ReactNode,
} from "react";
import { UserCredential, User } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserLogin, UserSignUp } from "src/constants/types/User";
import {
  redirectResult,
  requestForgetPwd,
  signIn,
  signInWithGoogle,
  signOut,
  signUp,
} from "src/utils/auth";
import { persistedUser } from "@/store/persistedHelper";
import { auth } from "@/firebase/firebase";
import { setheadersBearer } from "@/utils/api";
import { updatePassword } from "@vc-services/authSession";

export type UserContext =
  | {
      user: any;
      dispatch?: (user: any) => void;
      loading?: boolean;
    }
  | undefined;

export type AuthTypes = {
  user: User | null | undefined;
  loading?: boolean;
  userData?: any;
  setUserData?: (data: any) => void;
  signIn: ({ email, password }: UserLogin) => Promise<UserCredential | null>;
  signUp: ({
    email,
    password,
    username,
    phone,
  }: UserSignUp) => Promise<UserCredential | null>;
  signOut: () => void;
  signInWithGoogle: () => void;
  redirectResult: () => Promise<UserCredential | null>;
  requestForgetPwd: (email: string) => void;
  changePassword: ({ newPassword }: { newPassword: string }) => void;
};

const defaultContext = {
  user: null,
  signIn,
  signUp,
  signOut,
  signInWithGoogle,
  redirectResult,
  requestForgetPwd,
  changePassword,
};

export const AuthContext = createContext<AuthTypes>(defaultContext);

export function useAuth() {
  return useContext<AuthTypes>(AuthContext);
}
interface AuthProviderProps {
  children: ReactNode;
}

type AuthProviderType = React.FC<{ children: ReactNode }>;

export function RequireAuth({ children }: { children: ReactNode }) {
  const auth = useAuth();
  const location = useLocation();

  return auth?.loading ? (
    undefined
  ) : auth?.user ? (
    children
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
}

export async function changePassword({ newPassword }: any) {
  return await updatePassword(newPassword);
}
export const AuthProvider: AuthProviderType = ({
  children,
}: AuthProviderProps) => {
  const [user, loading] = useAuthState(auth);
  const [userData, setUserData] = useState<User | null | undefined>(null);

  useEffect(() => {
    // Fonction asynchrone pour obtenir un token

    if (user) {
      const fetchToken = async (forceRefresh: boolean = false) => {
        try {
          const idToken = await user.getIdToken(forceRefresh);
          setheadersBearer(idToken);
          persistedUser(
            JSON.stringify({
              uid: user.uid,
              accessToken: idToken,
            })
          );
          console.log("Token:", idToken);
          // Envoyer ce token au serveur si nécessaire
        } catch (err) {
          console.error("Erreur lors de la récupération du token:", err);
        }
      };

      fetchToken(true);
    }
  }, [user]);

  const value = {
    ...defaultContext,
    user,
    loading,
    userData,
    setUserData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
