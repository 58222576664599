import { api, apiAuth } from "@/utils/api";
import { InvoiceDataType } from "src/constants/types/Courses";

const createPaymentIntent = async (options: {}) => {
  const { data, status } = await api.post(
    `/Stripe/services/create-payment-intent`,
    options
  );

  if (status === 200) {
    return data.client_secret;
  }

  // console.log(res);
  throw new Error("PaymentIntent API Error");
};

const createCheckoutSession = async (options?: {}) => {
  const { data: sessions } = await apiAuth.post(
    `/Stripe/payment/session`,
    options
  );
  return sessions;
};

// get publish key stripe from server api
const getPublishKeyStripe = async (options?: {}) => {
  const res = await apiAuth.get("/Stripe/getPublishKey");
  try {
    if (res.status === 200) {
      const { data } = res;
      return data.publicKey;
    }
  } catch (error) {
    console.log("API error:", { error });
    throw Error("API Error");
  }
};
type InfoStripe = {
  email: string;
  displayName: string;
  userId: string;
};

export const createUserStripe = async (data?: InfoStripe) => {
  const { email, displayName, userId } = data || {};
  const response = await apiAuth.post("/Stripe/customer/create-customer", {
    userData: { email, name: displayName },
    userId,
  });
  return response.data;
};

const generateInvoice = async (data?: {}) => {
  return await apiAuth.post("/Stripe/payment/send-invoice", data);
};

export const downloadInvoice = async (data: InvoiceDataType) => {
  try {
    const resp = await apiAuth.post("/Stripe/payment/create-invoice", {
      sessionData: data,
    });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `facture_${data.created}.pdf`); // or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export const getSession = async (sessionId?: string | null) => {
  return await apiAuth.get(`/Stripe/payment/get-session/${sessionId}`);
};

export const reOpenSession = async (sessionId?: string | null) => {
  return await apiAuth.get(`/Stripe/payment/reopen-session/${sessionId}`);
};

export const cancelSession = async (sessionId: string | null) => {
  return await apiAuth.get(`/Stripe/payment/cancel-session/${sessionId}`);
};

export const getUserStripe = async ({
  customerId,
  cursor,
  limit,
}: Partial<{ customerId: string; limit: number; cursor: number }>) => {
  return await api.get(`/Stripe/customer/${customerId}?cursor=${cursor}&limit=${limit}`);
};

const stripeService = {
  getPublishKeyStripe,
  createPaymentIntent,
  createUserStripe,
  generateInvoice,
  createCheckoutSession,
};

export default stripeService;
