import { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Form } from "react-bootstrap";

// import { persistedUser } from "@/store/persistedHelper";

// import stripe from "@/services/stripeServices";

import * as ROUTES from "@/constants/routes";

//Context
import { useAuth } from "@/context/auth";
import GoogleSign from "@/components/SignIn/GoogleSignin";
import t from "src/i18n";

const SignUpPage = () => {
  return (
    <div className="Page__Signup Container__Sign">
      <h1 className="f-subTitle">{t("signup.title")}</h1>
      <SignUpForm />
    </div>
  );
};

interface State {
  [x: number]: string;
  username: string;
  lastName: string;
  firstName: string;
  email: string;
  passwordOne: string;
  passwordTwo: string;
  phone: string;
  error: any;
}

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  phone: "",
  firstName: "",
  lastName: "",
  error: null,
};

const SignUpFormBase = (props: any) => {
  const [form, setState] = useState<State>({ ...INITIAL_STATE }); // initiate my form
  // const { dispatch } = useContext(userContext); // dispatch to userContext
  const { signUp } = useAuth();

  const navigate = useNavigate();
  // Destructure state form
  const {
    username,
    email,
    passwordOne,
    passwordTwo,
    error,
    phone,
    lastName,
    firstName,
  } = form;

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (passwordOne === passwordTwo) {
      try {
        const { user } =
          (await signUp?.({
            email,
            password: encodeURIComponent(passwordOne),
            phone: encodeURIComponent(phone),
            lastName,
            firstName,
            username,
          })) || {};
        // const { user: authUser, token } = await resCreateUser.json();
        // persistedUser(
        //   JSON.stringify({
        //     uid: authUser.uid,
        //     accessToken: token,
        //   })
        // );
        // const authSign =
        //   !authUser.error && (await doSignInUserWithEmail(email, passwordOne));
        // getAuthSignIn(authUser.user.uid, data =>
        // authUser &&
        //   dispatch({
        //     type: "SET_USER",
        //     data: {
        //       ...authUser,
        //       info: authUser.providerData[0],
        //       isReady: false,
        //     },
        //   });
        // );
        // authUser.error
        //   ? setState({ ...form, error: authUser.error })
        //   : authUser && setState({ ...INITIAL_STATE });
        !props.onStep ? user && navigate(ROUTES.HOME) : props.setStep();
      } catch (error) {
        setState({ ...form, error });
        console.log("error on register", error);
      }
      // console.log(user);
    }

    // .then(() => {
    //   setState({ ...INITIAL_STATE });
    //   props.history.push(ROUTES.HOME);
    // })
    // .catch(error => {
    //   setState({ ...state, error });
    // });
  };

  const onChange = (event: any) => {
    setState({ ...form, [event.target.name]: event.target.value });
  };

  // render() {

  return (
    <Form onSubmit={onSubmit} className="Form Form__Signup">
      <Form.Group>
        <Form.Control
          name="username"
          value={username}
          onChange={onChange}
          type="text"
          placeholder="Nom d'utilisateur"
        />
        <Form.Control
          name="firstName"
          value={firstName}
          onChange={onChange}
          type="text"
          placeholder="Prenom"
        />
        <Form.Control
          name="lastName"
          value={lastName}
          onChange={onChange}
          type="text"
          placeholder="Nom"
        />
        <Form.Control
          name="phone"
          value={phone}
          onChange={onChange}
          type="phone"
          placeholder="phone"
        />
        <Form.Control
          name="email"
          value={email}
          onChange={onChange}
          type="text"
          placeholder="Email Address"
        />

        <Form.Group>
          <Form.Control
            name="passwordOne"
            value={passwordOne}
            onChange={onChange}
            type="password"
            placeholder="Password"
          />
          <Form.Control
            name="passwordTwo"
            value={passwordTwo}
            onChange={onChange}
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Group>
        <button
          className="btn v-button primary"
          type="submit"
          disabled={passwordOne === "" || passwordOne !== passwordTwo}
        >
          {t("form.signup")}
        </button>
        <GoogleSign />
      </Form.Group>
      {error && <p>{error.message}</p>}
    </Form>
  );
  // }
};
const SignUpLink = () => (
  <p>
    {t("signup.askSignup")} <Link to={ROUTES.SIGN_UP}>{t("signup.label")}</Link>
  </p>
);

export const SignUpForm = connect((state) => state)(SignUpFormBase);
export default SignUpPage;
export { SignUpLink };
