import { Route, Routes, useNavigate } from "react-router-dom";

import MainContent from "./MainContent";
import * as ROUTES from "@/constants/routes";
import HomePage from "@/components/Home";
import ForgetPassword from "@/components/Account/ForgetPassword";
import SignUp from "@/components/SignUp";
import SignIn from "@/components/SignIn";
import CheckPayment from "@/components/Paiement/CheckPayment";
import Layout from "@/components/Profile/layout";
import Account from "@/components/Profile/account";
import Profile from "@/components/Profile";
import { ProtectedRoute } from "@/components/App/ProtectedRoute";
import BookFormComponent from "@vc-components/BookForm/BookFormcomponent";
import Paiement from "@vc-components/Paiement";
import LayoutBooking from "@vc-components/BookForm/LayoutBooking";
import { useEffect } from "react";
import { setupInterceptors } from "@/utils/api";
import { HistorySection } from "@vc-components/Profile/history/history-section";

const App = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    setupInterceptors(navigate);
  }, [navigate]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainContent />}>
          <Route index element={<HomePage />} />
          <Route path={ROUTES.BOOKORLYLINK} element={<LayoutBooking />}>
            <Route index element={<BookFormComponent />} />
            <Route path={ROUTES.PAYMENTS} element={<Paiement />} />
          </Route>
          <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
          <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
          <Route path={ROUTES.CHECK} element={<CheckPayment />} />
          <Route
            path={ROUTES.PASSWORD_FORGOTTEN}
            element={<ForgetPassword />}
          />
          <Route
            path={ROUTES.SETTINGS}
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route index path={ROUTES.PROFILE} element={<Profile />} />
            <Route path={ROUTES.ACCOUNT} element={<Account />} />
            <Route path={ROUTES.HISTORY} element={<HistorySection />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
