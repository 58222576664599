import React, { Component } from "react";

import mapboxgl from "mapbox-gl";
import { MAPBOX_KEY } from "src/constants/secret";

// import { getRoutes } from '../../services/mapboxServices';

// import { CourseModel } from '../Courses'

class Mapbox extends Component {
  mapContainer = React.createRef<HTMLDivElement>();
  mapboxgl: any = (mapboxgl.accessToken = MAPBOX_KEY);
  public static map: any = null; // atteindre map from every component
  componentDidMount() {
    if (this.mapContainer.current)
      Mapbox.map = new mapboxgl.Map({
        container: this.mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v8",
        center: [2.341176, 48.8507837],
        zoom: 13
      });
  }

  render() {
    return <div ref={this.mapContainer} className="mapContainer" />;
  }
}

export default Mapbox;
