export const SIGN_UP: string = "/signup";
export const SIGN_IN: string = "/signin";
export const HOME: string = "/";
export const ACCOUNT: string = "account";
export const PASSWORD_FORGOTTEN: string = "/password_forgotten";
export const CHECK: string = "/check";
export const SETTINGS: string = "/settings";
export const PROFILE: string = "profile";
export const SECURITY: string = "/security";
export const BOOKORLYLINK: string = "/bookOrlyLink";
export const PAYMENTS: string = "payment-Orly";
export const HISTORY: string = "history";
