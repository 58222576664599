import swipe from "@vc-assets/images/arrow.svg";
import { useState } from "react";
const SwipeButton = ({
  onClick: onClickAction,
  ...props
}: {
  onClick: any;
}) => {
  const [rotate, setRotate] = useState(true);
  return (
    <div
      onClick={() => {
        setRotate((e) => !e);
        onClickAction();
      }}
      {...props}
      className={`button-swipe v-button bg-color-yellow round ${
        rotate ? "active" : ""
      }`}
    >
      <img className="w-5" alt="swipe-button" src={swipe} />
    </div>
  );
};

export default SwipeButton;
