import { useCallback, useState, useMemo } from "react";
import * as ROUTES from "src/constants/routes";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

import { makePayment } from "./utils";
import FullScreenModal from "@vc-components/FullScreenModal/FullScreenModal";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

const CheckoutForm = ({
  options,
  stripePromise,
}: {
  options?: any;
  stripePromise: Stripe | Promise<Stripe | null> | null;
}) => {
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState(options.sessionId);
  // useEffect(() => {
  //   async function redirect() {
  //     if (sessionId) {
  //       const stripe = await stripePromise;
  //       return await stripe?.redirectToCheckout({
  //         sessionId: options.sessionId,
  //       });
  //     }
  //   }
  //   redirect();
  // }, [options.sessionId, sessionId, stripePromise]);
  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    if (sessionId) {
      options.sessionId = sessionId;
    }
    try {
      const _session = await makePayment(options);
      setSessionId(_session.id);
      return _session.client_secret;
    } catch (error) {
      navigate(`${ROUTES.CHECK}/?status=error&session_id=${sessionId}`);
    }
  }, [options, sessionId, navigate]);
  const fetchClient = useMemo(
    () => ({
      fetchClientSecret,
    }),
    [fetchClientSecret]
  );

  return (
    <FullScreenModal
      isOpen={true}
      onClose={() =>
        navigate(`${ROUTES.CHECK}/?status=cancel&session_id=${sessionId}`)
      }
    >
      <EmbeddedCheckoutProvider stripe={stripePromise} options={fetchClient}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </FullScreenModal>
  );
};

/* const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to{" "}
          {customerEmail}. If you have any questions, please email{" "}
          <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
}; */

export default CheckoutForm;
