export const convertData = function (data: any) {
  let index = 0;
  // let addressResult = null;
  const { address } = data?.properties || {}
  data.context.forEach((e: any, i: number) => {
    return e.id.indexOf("place") > -1 && (index = i); // return l'index de la valeur de la ville si place est dans context
  });
  // this.props.setFill({ type: this.action, value: true })
  const place =
    data.place_type.indexOf("place") > -1
      ? data.text
      : data.context[index].text; //cherche où se trouve la valeur "place" dans l'objet et prend la valeur de la ville
  // addressResult =
  //   data.place_type[0] === "poi"
  //     ? data.text
  //     : data.place_name
  //       .split(",")
  //       .splice(-4)
  //       .join(",");
  return {
    place: place,
    placeDisplay: place,
    address: address,
    addressDisplay: address,
    coordinates: data?.geometry?.coordinates,
    completed: true
  };
};
