import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";

import params from "src/config/params.json";

import CheckoutForm from "./CheckoutForm";
import { useAuth } from "@/context/auth";
import { CourseModel } from "@/constants/types/Courses";

const stripePromise = loadStripe(params.REACT_APP_STRIPE_API_KEY);
const Paiement = ({ options }: { options?: any }) => {
  const { user, userData } = useAuth();
  const location = useLocation();
  const course: { course: CourseModel; sessionId: string } =
    location.state || {};
  const { displayName, email, phoneNumber, uid } = user || {};
  const { stripeCustomer } = userData || {};
  const RideInfo = options || {
    userAuth: { displayName, email, phoneNumber, uid, stripeCustomer },
    course,
    sessionId: course.sessionId,
  };
  console.log("sessionId", course);
  return <CheckoutForm options={RideInfo} stripePromise={stripePromise} />;
};

export default Paiement;
