import { removeTokenID } from '@/store/persistedHelper';
import axios from 'axios'
import config from "src/config/params.json";
import { signOut } from './auth';
import * as ROUTES from "src/constants/routes";


export const api = axios.create({
  baseURL: `${config.API}/API`
})
export const apiAuth = axios.create({
  baseURL: `${config.API}/API`
})
export const setheadersBearer = (id: string) =>
  apiAuth.defaults.headers.common['Authorization'] = "Bearer " + id;
export const setupInterceptors = (navigate: any) =>
  apiAuth.interceptors.response.use(
    (res) => {
      return res
    },
    (error) => {
      const { response: { data } } = error || {};
      const { error: { code } } = data || {};
      if (code === "auth/id-token-expired" || code === "auth/unauthenticated") {
        signOut();
        removeTokenID();
        navigate(ROUTES.SIGN_IN);
      }
      return Promise.reject(error);
    }
  )