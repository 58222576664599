"use client";

import * as React from "react";
import { Clock } from "lucide-react";
import { TimePickerInput } from "@/components/ui/time-picker-input";
import { ControllerRenderProps } from "react-hook-form";

interface TimePickerProps {
  date: Date;
  setDate: (date: Date) => void;
}

export const TimePicker = React.forwardRef<
  HTMLInputElement,
  TimePickerProps & ControllerRenderProps
>(({ date, setDate } , ref) => {
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);

  return (
    <div ref={ref} className="flex items-end gap-0 w-[100]">
      <div className="flex h-10 items-center bg-slate-100 rounded-s-full">
        <Clock className="mx-2 h-4 w-4 " />
      </div>
      <div className="flex text-center">
        <TimePickerInput
          picker="hours"
          aria-label="pick-hour"
          className="p-1 cursor-pointer bg-slate-100 outline-0 border-0"
          date={date}
          setDate={setDate}
          type="number"
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
        />
        <TimePickerInput
          picker="minutes"
          aria-label="pick-minutes"
          className="p-1 cursor-pointer bg-slate-100 rounded-e-full outline-0 border-0 ms-1"
          date={date}
          type="number"
          step={15}
          setDate={setDate}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
        />
      </div>
    </div>
  );
});
