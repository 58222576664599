"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { changePassword } from "@/context/auth";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@vc-components/ui/select";

const passwordFormSchema = z
  .object({
    currentPassword: z
      .string()
      .min(1, { message: "Le mot de passe actuel est requis." }),
    newPassword: z
      .string()
      .min(8, "Le nouveau mot de passe doit contenir au moins 8 caractères.")
      .regex(
        /[A-Z]/,
        "Le nouveau mot de passe doit contenir une lettre majuscule."
      )
      .regex(
        /[a-z]/,
        "Le nouveau mot de passe doit contenir une lettre minuscule."
      )
      .regex(/[0-9]/, "Le nouveau mot de passe doit contenir un chiffre."),
    confirmNewPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "Les nouveaux mots de passe ne correspondent pas.",
    path: ["confirmNewPassword"],
  });

const accountFormSchema = z.object({
  language: z.string(),
  email: z.string().email(),
});

type PasswordFormValues = z.infer<typeof passwordFormSchema>;
type AccountFormValues = z.infer<typeof accountFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<PasswordFormValues> = {};

export function AccountForm() {
  const formPassword = useForm<PasswordFormValues>({
    resolver: zodResolver(passwordFormSchema),
    defaultValues,
    mode: "onBlur",
    resetOptions: { keepErrors: false },
  });

  const formAccount = useForm<AccountFormValues>({
    resolver: zodResolver(accountFormSchema),
    resetOptions: { keepErrors: false },
  });

  async function onPasswordSubmit(data: PasswordFormValues) {
    console.log("data", data);
    await changePassword(data.newPassword);
   formPassword.reset();
  }

  function onSubmit(data: AccountFormValues) {
    console.log("data", data);
  }

  return (
    <div className="space-y-6 flex flex-col overflow-auto flex-1">
      <Form {...formAccount}>
        <form onSubmit={formAccount.handleSubmit(onSubmit)}>
          <FormField
            control={formAccount.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="email">Email</FormLabel>
                <FormControl>
                  <Input id="email" type="email" placeholder="e@exemple.com" />
                </FormControl>
                <FormDescription></FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formAccount.control}
            name="language"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Langue de l'application</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Langue de l'application" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="bg-white">
                    <SelectItem value="fr">Français</SelectItem>
                    <SelectItem value="en">English</SelectItem>
                  </SelectContent>
                </Select>
                <FormDescription>
                  La langue choisi sera utilisée dans l'application
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <button type="submit">Changer l'email</button>
        </form>
      </Form>
      <Form {...formPassword}>
        <h3 className="text-lg font-semibold">Changer le mot de passe</h3>
        <form
          onSubmit={formPassword.handleSubmit(onPasswordSubmit)}
          className="space-y-8"
        >
          <FormField
            name="currentPassword"
            control={formPassword.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Current Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Enter your current password"
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-red-500 text-sm font-medium" />
              </FormItem>
            )}
          />

          {/* New Password */}
          <FormField
            name="newPassword"
            control={formPassword.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>New Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Enter your new password"
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-red-500 text-sm font-medium" />
              </FormItem>
            )}
          />

          {/* Confirm New Password */}
          <FormField
            name="confirmNewPassword"
            control={formPassword.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm New Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Confirm your new password"
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-red-500 text-sm font-medium" />
              </FormItem>
            )}
          />

          {/* Submit Button */}
          <Button
            type="submit"
            className="w-full"
            disabled={formPassword.formState.isSubmitting}
          >
            {formPassword.formState.isSubmitting
              ? "Changing Password..."
              : "Change Password"}
          </Button>
        </form>
      </Form>
    </div>
  );
}
