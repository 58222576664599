import {
  getAuth,
  signInWithCustomToken,
  signOut as firebaseSignOut,
  signInWithPopup,
  getRedirectResult,
  GoogleAuthProvider,
} from "firebase/auth";
import * as apiService from "src/services/authSession";
import { UserLogin, UserSignUp } from "src/constants/types/User";
import { createUserFromRedirect } from "@/firebase/firebase";
import { setheadersBearer } from "./api";

export const signUp = async ({
  email,
  password,
  username,
  phone,
  firstName,
  lastName,
}: UserSignUp) => {
  const { token } = await apiService.signUp({
    email,
    password,
    username,
    phone,
    firstName,
    lastName,
    role: 'driver'
  });
  return await signInWithCustomToken(getAuth(), token);
};

export const signIn = async ({ email, password }: UserLogin) => {
  const auth = getAuth();
  const { token } = await apiService.signIn({
    email,
    password,
  });
  return await signInWithCustomToken(auth, token);
};

export const signInWithGoogle = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  try {
    const { user } = await signInWithPopup(auth, provider);
    const { displayName, phoneNumber, uid } = user;
    // ne pas appeler si on a déjà créer l'utilisateur
    if (user) {
      const userIdToken = await user.getIdToken();
      setheadersBearer(userIdToken);
      const userdata = await apiService.getUserData({ userIdToken, userId: user.uid });
      if (!userdata && user) {
        createUserFromRedirect({
          displayName,
          phone: phoneNumber,
          uid,
        });
      }
    }

  } catch (error) {
    console.error(error)
  }
  // signInWithRedirect(auth, provider);
};


export const redirectResult = async () => {
  const auth = getAuth();
  return await getRedirectResult(auth);
};

export const signOut = async () => {
  const auth = getAuth();
  return await firebaseSignOut(auth);
};

export const requestForgetPwd = async (email: string) => await apiService.requestForgetPwd(email);
