import { dateFormat } from "@/utils/date_utils";
import { Button } from "@vc-components/ui/button";
import { useTranslation } from "react-i18next";
import Stripe from "stripe";

type ListItemRideProps = {
  ride: Stripe.Checkout.Session;
};

export const ListItemRide = ({ ride }: ListItemRideProps) => {
  const { t } = useTranslation();
  const { invoice_creation, /* status */ } = ride;
  const { invoice_data } = invoice_creation || {};
  const { metadata } = invoice_data || {};
  const { datetime, /* depart, price, arrive, */ type = "OrlyLink" } = metadata || {};
  const date = dateFormat(datetime, t("dateformat.short"));
  return (
    <li
      key={ride?.id}
      className="flex items-center justify-between bg-gray-100 p-3 rounded"
    >
      <span>{t("history.rideCardDescription", { type, date })}</span>
      <Button variant="link">View Details</Button>
    </li>
  );
};
