"use client";

import { useState, useEffect, useCallback } from "react";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";

interface FullscreenModalProps {
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}

export default function FullScreenModal({
  children,
  isOpen = false,
  onClose,
}: FullscreenModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") closeModal();
    };

    if (isModalOpen) {
      document.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "visible";
    };
  }, [isModalOpen, closeModal]);

  if (!isModalOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-background/80 backdrop-blur-sm"
      onClick={closeModal}
      role="dialog"
      aria-modal="true"
    >
      <div
        className="relative h-full w-full overflow-auto bg-background p-6"
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          className="absolute right-4 top-4"
          variant="ghost"
          size="icon"
          onClick={closeModal}
          aria-label="Close modal"
        >
          <X className="h-4 w-4" />
        </Button>
        <div className="mt-8">{children}</div>
      </div>
    </div>
  );
}
