import stripeApi, { reOpenSession } from "src/services/stripeServices";

export const makePayment = async ({ userAuth, course, sessionId }) => {
  const { stripeCustomer, ...rest } = userAuth
  const {type, depart, arrive, startDate, ...restRide} = course || {}
  const dataFormatted = formatSessionData({
    item_data: {
      type,
      depart,
      arrive,
      startDate,
      ...restRide
    },
    mode: "payment",
    payment_method_types: ["card"],
    customer: stripeCustomer,
    customer_email: !stripeCustomer ? userAuth.email : null,
    metadata: {
      ...rest,
    },
  })
  if(sessionId) {
    try {
      const session = await reOpenSession(sessionId);
      return session.data
    } catch (error) {
      console.error('error on getting Session', error)
    }
  } else {

    try {
      
      const session = await stripeApi.createCheckoutSession(dataFormatted);
      // if (session.id) {
      //   window.open(session.url, "_self");
      // }
  
      return session
      // await stripe.redirectToCheckout({ sessionId: session.id });
      // setClientSecret(sessionId);
    } catch (error) {
      console.error("error", error);
      return "Une erreur s'est produite";
    }
  }
};

export const fetchClientSecret = async (_session) => {
  // Create a Checkout Session
  return _session.client_secret;
}

const formatSessionData = (obj) => {
  for (const key in obj) {
    if (obj[key] == null) {
      delete obj[key];
    }
  }
  return obj
}