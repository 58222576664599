import { useQuery } from "src/utils/utils";
import PaymentStatus from "./PaymentStatus";

export default function CheckPayment() {
  const query = useQuery();
  return (
    <PaymentStatus
      status={query.get("status")}
      sessionId={query.get('session_id')}
    />
  );
}
