import React, { useState } from "react";

import { useAuth } from "@/context/auth";

import * as apiService from "@/services/authSession";

import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { ERRORS } from "@/constants/Errors";
import { setheadersBearer } from "@/utils/api";
import * as ROUTES from "@/constants/routes";

// import { PasswordChangeForm } from "../PasswordChange";
// import { PasswordForgetForm } from "../PasswordForget/PasswordForgetForm";
// import { withAuthorization } from "../Session/withAuthorization";
const AccountComponent = () : JSX.Element => {
  // const [authUser, setUser] = useState({ ...initUser });
  const { user, loading, setUserData, redirectResult } = useAuth();
  const [name, setName] = useState<string | null>(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          const { displayName, email, uid } = user;
          setName(displayName);
          // here is the code to try fetch all information of user data
          const userIdToken = await user.getIdToken();
          setheadersBearer(userIdToken);
          // const userRedirectCred = await redirectResult?.();
          try {
            const originInfoUser = {
              email,
            };
            const userData = await apiService.getUserData({
              userIdToken,
              userId: uid,
            });
            // user sign in with redirect is already create
            // add it in database if not exist
            if (
              // userRedirectCred &&
              userData?.error?.code === ERRORS.USER.NOTFOUND
            ) {
              delete userData.error;
              // const { user: userCred } = userRedirectCred;
              // const {
              //   displayName: nameCred,
              //   phoneNumber: phoneCred,
              // } = userCred;
              // await createUserFromRedirect({
              //   displayName: nameCred,
              //   phone: phoneCred,
              //   uid,
              // });
            }
            setUserData?.({ ...userData, ...originInfoUser });
            setName(displayName);
          } catch (error) {
            console.log("error on getUserData", error);
          }
        } else {
          setName(null);
        }
      }
    })();
     
  }, [user, loading, setUserData, redirectResult]);

  return (
    <div>
      <span className="f-Circular text-capitalize text-white">
        {name ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${ROUTES.SETTINGS}/${ROUTES.PROFILE}`)}
          >
            {name}
          </span>
        ) : (
          loading && <Spinner animation="border" role="status" size="sm" />
        )}
      </span>
    </div>
  );
};

export default AccountComponent;
